/* tslint:disable */
/* eslint-disable */
/**
 * GUD.WorkshopPortal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressInformationModel
 */
export interface AddressInformationModel {
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'street1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'street2': string;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'suburb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof AddressInformationModel
     */
    'telephone': string;
}
/**
 * 
 * @export
 * @interface AdvanceWorkshopViewModel
 */
export interface AdvanceWorkshopViewModel {
    /**
     * 
     * @type {string}
     * @memberof AdvanceWorkshopViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AdvanceWorkshopViewModel
     */
    'balance': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApplicationClaimType = {
    ManageProducts: 'ManageProducts',
    ManageUsers: 'ManageUsers',
    ManageWorkshops: 'ManageWorkshops',
    ManageClaims: 'ManageClaims',
    AdvanceSystemUser: 'AdvanceSystemUser',
    ManageReturns: 'ManageReturns',
    ViewReturns: 'ViewReturns',
    ViewProducts: 'ViewProducts',
    ViewUsers: 'ViewUsers',
    ViewWorkshops: 'ViewWorkshops',
    ViewClaims: 'ViewClaims',
    ViewAuditLogs: 'ViewAuditLogs',
    ViewBulletins: 'ViewBulletins',
    ManageBulletins: 'ManageBulletins',
    ViewRewardsStore: 'ViewRewardsStore'
} as const;

export type ApplicationClaimType = typeof ApplicationClaimType[keyof typeof ApplicationClaimType];


/**
 * 
 * @export
 * @interface AuditLogDto
 */
export interface AuditLogDto {
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'newValue': string;
    /**
     * 
     * @type {AuditTypes}
     * @memberof AuditLogDto
     */
    'auditLogTypesId': AuditTypes;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'workshopId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'typePrimaryKey': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'workshopName': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof AuditLogDto
     */
    'createdByUserDisplayName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuditTypes = {
    User: 'User',
    ApplicationUserRole: 'ApplicationUserRole',
    Workshop: 'Workshop',
    LoyaltyPointsTransaction: 'LoyaltyPointsTransaction',
    WorkshopUser: 'WorkshopUser'
} as const;

export type AuditTypes = typeof AuditTypes[keyof typeof AuditTypes];


/**
 * 
 * @export
 * @interface BulkImportProductsCommand
 */
export interface BulkImportProductsCommand {
    /**
     * 
     * @type {string}
     * @memberof BulkImportProductsCommand
     */
    'fileRelativeUrl': string;
}
/**
 * 
 * @export
 * @interface BulkImportProductsSkuAliasesCommand
 */
export interface BulkImportProductsSkuAliasesCommand {
    /**
     * 
     * @type {string}
     * @memberof BulkImportProductsSkuAliasesCommand
     */
    'fileRelativeUrl': string;
}
/**
 * 
 * @export
 * @interface BulkReviewLoyaltyPointsClaimAdminCommand
 */
export interface BulkReviewLoyaltyPointsClaimAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'searchText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'workshopId'?: string | null;
    /**
     * 
     * @type {SortDirection}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'sortDirection': SortDirection;
    /**
     * 
     * @type {boolean}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'isFullMatch'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'dateSubmittedStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'dateSubmittedEndDate'?: string | null;
    /**
     * 
     * @type {LoyaltyPointClaimStatus}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'reviewStatusId': LoyaltyPointClaimStatus;
    /**
     * 
     * @type {string}
     * @memberof BulkReviewLoyaltyPointsClaimAdminCommand
     */
    'reviewComment'?: string | null;
}
/**
 * 
 * @export
 * @interface BulletinDto
 */
export interface BulletinDto {
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'thumbnailRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'contentRelativeUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BulletinDto
     */
    'publishDate': string;
    /**
     * 
     * @type {BulletinType}
     * @memberof BulletinDto
     */
    'bulletinTypesId': BulletinType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BulletinType = {
    Bulletin: 'Bulletin',
    Promotion: 'Promotion'
} as const;

export type BulletinType = typeof BulletinType[keyof typeof BulletinType];


/**
 * 
 * @export
 * @interface ClaimListViewModel
 */
export interface ClaimListViewModel {
    /**
     * 
     * @type {ApplicationClaimType}
     * @memberof ClaimListViewModel
     */
    'id': ApplicationClaimType;
    /**
     * 
     * @type {string}
     * @memberof ClaimListViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ConfirmEmailDto
 */
export interface ConfirmEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmEmailDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface CreateBulletinCommand
 */
export interface CreateBulletinCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateBulletinCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBulletinCommand
     */
    'thumbnailRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBulletinCommand
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBulletinCommand
     */
    'contentRelativeUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBulletinCommand
     */
    'publishDate': string;
    /**
     * 
     * @type {BulletinType}
     * @memberof CreateBulletinCommand
     */
    'bulletinTypesId': BulletinType;
}
/**
 * 
 * @export
 * @interface CreateLoyaltyPointsManualTransactionCommand
 */
export interface CreateLoyaltyPointsManualTransactionCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointsManualTransactionCommand
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointsManualTransactionCommand
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointsManualTransactionCommand
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateLoyaltyPointsManualTransactionCommand
     */
    'total': number;
    /**
     * 
     * @type {string}
     * @memberof CreateLoyaltyPointsManualTransactionCommand
     */
    'trackingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateProductCommand
 */
export interface CreateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProductCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductLoyaltyPointValueCreateUpdateModel>}
     * @memberof CreateProductCommand
     */
    'productLoyaltyPointValues': Array<ProductLoyaltyPointValueCreateUpdateModel>;
    /**
     * 
     * @type {Array<ProductSkuAliasCreateUpdateViewModel>}
     * @memberof CreateProductCommand
     */
    'productSkuAliases': Array<ProductSkuAliasCreateUpdateViewModel>;
}
/**
 * 
 * @export
 * @interface CreateRoleCommand
 */
export interface CreateRoleCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof CreateRoleCommand
     */
    'claims': Array<ApplicationClaimType>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRoleCommand
     */
    'isWorkshopRole': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleCommand
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserCommand
 */
export interface CreateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserCommand
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {Array<WorkshopUserRoleCreateUpdateModel>}
     * @memberof CreateUserCommand
     */
    'workshopRoles': Array<WorkshopUserRoleCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'cellNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserCommand
     */
    'acceptedPrivicyPolicy': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'workshopName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserCommand
     */
    'skynamoReferenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateWorkshopCommand
 */
export interface CreateWorkshopCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkshopCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkshopCommand
     */
    'advanceUserEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkshopCommand
     */
    'skynamoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkshopCommand
     */
    'relativeImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface ExtractedLineItemListViewModel
 */
export interface ExtractedLineItemListViewModel {
    /**
     * 
     * @type {number}
     * @memberof ExtractedLineItemListViewModel
     */
    'confidence': number;
    /**
     * 
     * @type {string}
     * @memberof ExtractedLineItemListViewModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractedLineItemListViewModel
     */
    'productCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExtractedLineItemListViewModel
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtractedLineItemListViewModel
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractedLineItemListViewModel
     */
    'loyaltyPointsClaimExtractedDataDocumentId': string;
    /**
     * 
     * @type {string}
     * @memberof ExtractedLineItemListViewModel
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtractedLineItemListViewModel
     */
    'matchedProductSkus': Array<string>;
}
/**
 * 
 * @export
 * @interface FilePath
 */
export interface FilePath {
    /**
     * 
     * @type {string}
     * @memberof FilePath
     */
    'relativePath'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilePath
     */
    'absolutePath': boolean;
}
/**
 * 
 * @export
 * @interface ImportDto
 */
export interface ImportDto {
    /**
     * 
     * @type {string}
     * @memberof ImportDto
     */
    'id': string;
    /**
     * 
     * @type {ImportTypeEnum}
     * @memberof ImportDto
     */
    'fileRelativeUrl': ImportTypeEnum;
    /**
     * 
     * @type {ImportStatusEnum}
     * @memberof ImportDto
     */
    'importStatusId': ImportStatusEnum;
    /**
     * 
     * @type {Array<ImportImportErrorDto>}
     * @memberof ImportDto
     */
    'importErrors': Array<ImportImportErrorDto>;
    /**
     * 
     * @type {number}
     * @memberof ImportDto
     */
    'importTypeId': number;
}
/**
 * 
 * @export
 * @interface ImportImportErrorDto
 */
export interface ImportImportErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'exception'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ImportImportErrorDto
     */
    'importId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ImportStatusEnum = {
    New: 'New',
    Pending: 'Pending',
    InProgress: 'InProgress',
    Complete: 'Complete',
    Error: 'Error'
} as const;

export type ImportStatusEnum = typeof ImportStatusEnum[keyof typeof ImportStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const ImportTypeEnum = {
    Product: 'Product',
    SkuAlias: 'SkuAlias'
} as const;

export type ImportTypeEnum = typeof ImportTypeEnum[keyof typeof ImportTypeEnum];


/**
 * 
 * @export
 * @interface ImportWorkshopsCommand
 */
export interface ImportWorkshopsCommand {
    /**
     * 
     * @type {string}
     * @memberof ImportWorkshopsCommand
     */
    'relativeFileUrl': string;
}
/**
 * 
 * @export
 * @interface JsonResponseOfGuid
 */
export interface JsonResponseOfGuid {
    /**
     * 
     * @type {string}
     * @memberof JsonResponseOfGuid
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface JsonResponseOfListOfString
 */
export interface JsonResponseOfListOfString {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonResponseOfListOfString
     */
    'value': Array<string>;
}
/**
 * 
 * @export
 * @interface JsonResponseOfString
 */
export interface JsonResponseOfString {
    /**
     * 
     * @type {string}
     * @memberof JsonResponseOfString
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoyaltyPointClaimListViewModel
 */
export interface LoyaltyPointClaimListViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {LoyaltyPointClaimStatus}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'loyaltyPointsClaimStatusId': LoyaltyPointClaimStatus;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'loyaltyPointsClaimStatusName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'workshopName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'dateSubmitted': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'isFullMatch': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimListViewModel
     */
    'companyName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LoyaltyPointClaimStatus = {
    Pending: 'Pending',
    Approved: 'Approved',
    Rejected: 'Rejected',
    AwaitingResubmission: 'AwaitingResubmission',
    AwaitingDataExtraction: 'AwaitingDataExtraction'
} as const;

export type LoyaltyPointClaimStatus = typeof LoyaltyPointClaimStatus[keyof typeof LoyaltyPointClaimStatus];


/**
 * 
 * @export
 * @interface LoyaltyPointClaimViewModel
 */
export interface LoyaltyPointClaimViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'invoiceRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {LoyaltyPointClaimStatus}
     * @memberof LoyaltyPointClaimViewModel
     */
    'loyaltyPointsClaimStatusId': LoyaltyPointClaimStatus;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'reviewComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'reviewedByUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'reviewedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'submittedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'loyaltyPointsClaimStatusName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'reviewedByUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'submittedByUserDisplayName': string;
    /**
     * 
     * @type {Array<LoyaltyPointUpdateProductListViewModel>}
     * @memberof LoyaltyPointClaimViewModel
     */
    'products': Array<LoyaltyPointUpdateProductListViewModel>;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'workshopName': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointClaimViewModel
     */
    'totalValue': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'dateSubmitted': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof LoyaltyPointClaimViewModel
     */
    'pageNumbers': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointClaimViewModel
     */
    'trackingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointTransactionListViewModel
 */
export interface LoyaltyPointTransactionListViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'spent'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'expiryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'dateAdded': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'loyaltyPointsUpdateReferenceNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'isClaim': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'loyaltyPointsUpdateId': string;
    /**
     * 
     * @type {ReturnsStatus}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'loyaltyPointsTranactionReturnsStatusId': ReturnsStatus;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'loyaltyPointsUpdateCanReturn': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointTransactionListViewModel
     */
    'loyaltyPointsTransactionTrackingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointUpdateProductListViewModel
 */
export interface LoyaltyPointUpdateProductListViewModel {
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'productSku': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'productId'?: string | null;
    /**
     * 
     * @type {ExtractedLineItemListViewModel}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'loyaltyPointsClaimExtractedDataDocumentLineItem': ExtractedLineItemListViewModel;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointUpdateProductListViewModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface LoyaltyPointsClaimProductCreateUpdateModel
 */
export interface LoyaltyPointsClaimProductCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsClaimProductCreateUpdateModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointsClaimProductCreateUpdateModel
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsClaimProductCreateUpdateModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsClaimProductCreateUpdateModel
     */
    'extractedLineItemId'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointsManualTransactionViewModel
 */
export interface LoyaltyPointsManualTransactionViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'submittedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'totalValue': number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'workshopName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'submittedByUserDisplayName': string;
    /**
     * 
     * @type {Array<PurchasedProductViewModel>}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'loyaltyPointsUpdateLoyaltyPointUpdatePurchasedProducts': Array<PurchasedProductViewModel>;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'invoiceHtml'?: string | null;
    /**
     * 
     * @type {ReturnsStatus}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'loyaltyPointsTranactionReturnsStatusId': ReturnsStatus;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'canReturn': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsManualTransactionViewModel
     */
    'trackingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface LoyaltyPointsTranactionReturnDto
 */
export interface LoyaltyPointsTranactionReturnDto {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'loyaltyPointsUpdateId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'returnReason': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'workshopId': string;
    /**
     * 
     * @type {ReturnsStatus}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'returnsStatusId': ReturnsStatus;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'skynamoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'submittedByUserId': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'workshopName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'submittedByUserDisplayName': string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'createdDate': string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyPointsTranactionReturnDto
     */
    'taskId': number;
}
/**
 * 
 * @export
 * @interface OrderCreatedCommand
 */
export interface OrderCreatedCommand {
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'userEmail': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'userFirstName': string;
    /**
     * 
     * @type {number}
     * @memberof OrderCreatedCommand
     */
    'loyaltyPointsUsed': number;
    /**
     * 
     * @type {Array<PurchasedProductCreateModel>}
     * @memberof OrderCreatedCommand
     */
    'products': Array<PurchasedProductCreateModel>;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'orderReferenceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'shippingMethodCode': string;
    /**
     * 
     * @type {AddressInformationModel}
     * @memberof OrderCreatedCommand
     */
    'addressInformation': AddressInformationModel;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'orderInstruction'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCreatedCommand
     */
    'isDelivery': boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderCreatedCommand
     */
    'deliveryFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCreatedCommand
     */
    'orderTotal': number;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'orderPlacementDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderCreatedCommand
     */
    'invoiceHtml': string;
}
/**
 * 
 * @export
 * @interface PagedResultOfAuditLogDto
 */
export interface PagedResultOfAuditLogDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfAuditLogDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<AuditLogDto>}
     * @memberof PagedResultOfAuditLogDto
     */
    'data': Array<AuditLogDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfBulletinDto
 */
export interface PagedResultOfBulletinDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfBulletinDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfBulletinDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfBulletinDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfBulletinDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<BulletinDto>}
     * @memberof PagedResultOfBulletinDto
     */
    'data': Array<BulletinDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfClaimListViewModel
 */
export interface PagedResultOfClaimListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfClaimListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ClaimListViewModel>}
     * @memberof PagedResultOfClaimListViewModel
     */
    'data': Array<ClaimListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfImportDto
 */
export interface PagedResultOfImportDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfImportDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfImportDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfImportDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfImportDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ImportDto>}
     * @memberof PagedResultOfImportDto
     */
    'data': Array<ImportDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfLoyaltyPointClaimListViewModel
 */
export interface PagedResultOfLoyaltyPointClaimListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointClaimListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointClaimListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointClaimListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointClaimListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<LoyaltyPointClaimListViewModel>}
     * @memberof PagedResultOfLoyaltyPointClaimListViewModel
     */
    'data': Array<LoyaltyPointClaimListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfLoyaltyPointTransactionListViewModel
 */
export interface PagedResultOfLoyaltyPointTransactionListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointTransactionListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointTransactionListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointTransactionListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointTransactionListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<LoyaltyPointTransactionListViewModel>}
     * @memberof PagedResultOfLoyaltyPointTransactionListViewModel
     */
    'data': Array<LoyaltyPointTransactionListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfLoyaltyPointsTranactionReturnDto
 */
export interface PagedResultOfLoyaltyPointsTranactionReturnDto {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointsTranactionReturnDto
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointsTranactionReturnDto
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointsTranactionReturnDto
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfLoyaltyPointsTranactionReturnDto
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<LoyaltyPointsTranactionReturnDto>}
     * @memberof PagedResultOfLoyaltyPointsTranactionReturnDto
     */
    'data': Array<LoyaltyPointsTranactionReturnDto>;
}
/**
 * 
 * @export
 * @interface PagedResultOfProductListViewModel
 */
export interface PagedResultOfProductListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfProductListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<ProductListViewModel>}
     * @memberof PagedResultOfProductListViewModel
     */
    'data': Array<ProductListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfRoleListViewModel
 */
export interface PagedResultOfRoleListViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfRoleListViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<RoleListViewModel>}
     * @memberof PagedResultOfRoleListViewModel
     */
    'data': Array<RoleListViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfUserViewModel
 */
export interface PagedResultOfUserViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfUserViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<UserViewModel>}
     * @memberof PagedResultOfUserViewModel
     */
    'data': Array<UserViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfWorkshopUserViewModel
 */
export interface PagedResultOfWorkshopUserViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopUserViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopUserViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopUserViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopUserViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<WorkshopUserViewModel>}
     * @memberof PagedResultOfWorkshopUserViewModel
     */
    'data': Array<WorkshopUserViewModel>;
}
/**
 * 
 * @export
 * @interface PagedResultOfWorkshopViewModel
 */
export interface PagedResultOfWorkshopViewModel {
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopViewModel
     */
    'totalCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopViewModel
     */
    'pageCount': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopViewModel
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultOfWorkshopViewModel
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<WorkshopViewModel>}
     * @memberof PagedResultOfWorkshopViewModel
     */
    'data': Array<WorkshopViewModel>;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductListViewModel
 */
export interface ProductListViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof ProductListViewModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProductListViewModel
     */
    'currentLoyaltyPointValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListViewModel
     */
    'canDelete': boolean;
}
/**
 * 
 * @export
 * @interface ProductLoyaltyPointValueCreateUpdateModel
 */
export interface ProductLoyaltyPointValueCreateUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof ProductLoyaltyPointValueCreateUpdateModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueCreateUpdateModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueCreateUpdateModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueCreateUpdateModel
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductLoyaltyPointValueViewModel
 */
export interface ProductLoyaltyPointValueViewModel {
    /**
     * 
     * @type {number}
     * @memberof ProductLoyaltyPointValueViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueViewModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueViewModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueViewModel
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductLoyaltyPointValueViewModel
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ProductSkuAliasCreateUpdateViewModel
 */
export interface ProductSkuAliasCreateUpdateViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSkuAliasCreateUpdateViewModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductSkuAliasCreateUpdateViewModel
     */
    'aliasName': string;
}
/**
 * 
 * @export
 * @interface ProductSkuAliasViewModel
 */
export interface ProductSkuAliasViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductSkuAliasViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductSkuAliasViewModel
     */
    'aliasName': string;
}
/**
 * 
 * @export
 * @interface ProductViewModel
 */
export interface ProductViewModel {
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof ProductViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductLoyaltyPointValueViewModel>}
     * @memberof ProductViewModel
     */
    'productLoyaltyPointValues': Array<ProductLoyaltyPointValueViewModel>;
    /**
     * 
     * @type {Array<ProductSkuAliasViewModel>}
     * @memberof ProductViewModel
     */
    'productSkuAliases': Array<ProductSkuAliasViewModel>;
}
/**
 * 
 * @export
 * @interface PurchasedProductCreateModel
 */
export interface PurchasedProductCreateModel {
    /**
     * 
     * @type {string}
     * @memberof PurchasedProductCreateModel
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PurchasedProductCreateModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PurchasedProductCreateModel
     */
    'sku': string;
    /**
     * 
     * @type {number}
     * @memberof PurchasedProductCreateModel
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface PurchasedProductViewModel
 */
export interface PurchasedProductViewModel {
    /**
     * 
     * @type {number}
     * @memberof PurchasedProductViewModel
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PurchasedProductViewModel
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PurchasedProductViewModel
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedProductViewModel
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RegisterAccountCommand
 */
export interface RegisterAccountCommand {
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountCommand
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterAccountCommand
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'cellNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterDto
     */
    'acceptedPrivicyPolicy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'workshopName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'skynamoReferenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ResubmitLoyaltyPointsClaimCommand
 */
export interface ResubmitLoyaltyPointsClaimCommand {
    /**
     * 
     * @type {string}
     * @memberof ResubmitLoyaltyPointsClaimCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResubmitLoyaltyPointsClaimCommand
     */
    'invoiceRelativeUrl': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReturnsStatus = {
    Pending: 'Pending',
    Approved: 'Approved',
    Rejected: 'Rejected'
} as const;

export type ReturnsStatus = typeof ReturnsStatus[keyof typeof ReturnsStatus];


/**
 * 
 * @export
 * @interface ReviewLoyaltyPointsClaimAdminCommand
 */
export interface ReviewLoyaltyPointsClaimAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {LoyaltyPointClaimStatus}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'loyaltyPointsClaimStatusId': LoyaltyPointClaimStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'reviewComment'?: string | null;
    /**
     * 
     * @type {Array<LoyaltyPointsClaimProductCreateUpdateModel>}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'products': Array<LoyaltyPointsClaimProductCreateUpdateModel>;
    /**
     * 
     * @type {number}
     * @memberof ReviewLoyaltyPointsClaimAdminCommand
     */
    'totalOverrideValue'?: number | null;
}
/**
 * 
 * @export
 * @interface RoleListViewModel
 */
export interface RoleListViewModel {
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleListViewModel
     */
    'isWorkshopRole': boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleListViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface RoleViewModel
 */
export interface RoleViewModel {
    /**
     * 
     * @type {string}
     * @memberof RoleViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleViewModel
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof RoleViewModel
     */
    'claims': Array<ApplicationClaimType>;
    /**
     * 
     * @type {boolean}
     * @memberof RoleViewModel
     */
    'isWorkshopRole': boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleViewModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @interface SubmitLoyaltyPointsClaimAdminCommand
 */
export interface SubmitLoyaltyPointsClaimAdminCommand {
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'invoiceRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'workshopId': string;
    /**
     * 
     * @type {Array<LoyaltyPointsClaimProductCreateUpdateModel>}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'products': Array<LoyaltyPointsClaimProductCreateUpdateModel>;
    /**
     * 
     * @type {number}
     * @memberof SubmitLoyaltyPointsClaimAdminCommand
     */
    'totalOverrideValue'?: number | null;
}
/**
 * 
 * @export
 * @interface SubmitLoyaltyPointsClaimCommand
 */
export interface SubmitLoyaltyPointsClaimCommand {
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimCommand
     */
    'invoiceRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsClaimCommand
     */
    'workshopId': string;
}
/**
 * 
 * @export
 * @interface SubmitLoyaltyPointsReturnCommand
 */
export interface SubmitLoyaltyPointsReturnCommand {
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsReturnCommand
     */
    'returnReason': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsReturnCommand
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitLoyaltyPointsReturnCommand
     */
    'loyaltyPointsUpdateId': string;
}
/**
 * 
 * @export
 * @interface TokenResultDto
 */
export interface TokenResultDto {
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'authenticationToken': string;
    /**
     * 
     * @type {string}
     * @memberof TokenResultDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface UpdateBulletinCommand
 */
export interface UpdateBulletinCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'thumbnailRelativeUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'contentRelativeUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBulletinCommand
     */
    'publishDate': string;
    /**
     * 
     * @type {BulletinType}
     * @memberof UpdateBulletinCommand
     */
    'bulletinTypesId': BulletinType;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyPointsTranactionReturnCommand
 */
export interface UpdateLoyaltyPointsTranactionReturnCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointsTranactionReturnCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointsTranactionReturnCommand
     */
    'returnReason': string;
    /**
     * 
     * @type {ReturnsStatus}
     * @memberof UpdateLoyaltyPointsTranactionReturnCommand
     */
    'returnsStatusId': ReturnsStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointsTranactionReturnCommand
     */
    'skynamoId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateLoyaltyPointsTransactionTrackingNumber
 */
export interface UpdateLoyaltyPointsTransactionTrackingNumber {
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointsTransactionTrackingNumber
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLoyaltyPointsTransactionTrackingNumber
     */
    'trackingNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProductCommand
 */
export interface UpdateProductCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    'sku': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProductCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ProductLoyaltyPointValueCreateUpdateModel>}
     * @memberof UpdateProductCommand
     */
    'productLoyaltyPointValues': Array<ProductLoyaltyPointValueCreateUpdateModel>;
    /**
     * 
     * @type {Array<ProductSkuAliasCreateUpdateViewModel>}
     * @memberof UpdateProductCommand
     */
    'productSkuAliases': Array<ProductSkuAliasCreateUpdateViewModel>;
}
/**
 * 
 * @export
 * @interface UpdateRoleCommand
 */
export interface UpdateRoleCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleCommand
     */
    'name': string;
    /**
     * 
     * @type {Array<ApplicationClaimType>}
     * @memberof UpdateRoleCommand
     */
    'claims': Array<ApplicationClaimType>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleCommand
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserCommand
 */
export interface UpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserCommand
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {Array<WorkshopUserRoleCreateUpdateModel>}
     * @memberof UpdateUserCommand
     */
    'workshopRoles': Array<WorkshopUserRoleCreateUpdateModel>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'cellNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserCommand
     */
    'isVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCommand
     */
    'skynamoReferenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateWorkshopCommand
 */
export interface UpdateWorkshopCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopCommand
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopCommand
     */
    'name': string;
    /**
     * 
     * @type {WorkshopStatus}
     * @memberof UpdateWorkshopCommand
     */
    'workshopStatusId': WorkshopStatus;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopCommand
     */
    'advanceUserEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopCommand
     */
    'skynamoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopCommand
     */
    'relativeImageUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateWorkshopUserCommand
 */
export interface UpdateWorkshopUserCommand {
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopUserCommand
     */
    'workshopId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateWorkshopUserCommand
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkshopUserCommand
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface UserViewModel
 */
export interface UserViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserViewModel
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {Array<WorkshopUserRoleViewModel>}
     * @memberof UserViewModel
     */
    'workshopRoles': Array<WorkshopUserRoleViewModel>;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'cellNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModel
     */
    'acceptedPrivicyPolicy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModel
     */
    'isEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'workshopName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModel
     */
    'isWorkshopUser': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserViewModel
     */
    'isVerified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    'skynamoReferenceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkshopBalanceViewModel
 */
export interface WorkshopBalanceViewModel {
    /**
     * 
     * @type {number}
     * @memberof WorkshopBalanceViewModel
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof WorkshopBalanceViewModel
     */
    'totalExpiringSoon': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkshopStatus = {
    New: 'New',
    Active: 'Active',
    Inactive: 'Inactive'
} as const;

export type WorkshopStatus = typeof WorkshopStatus[keyof typeof WorkshopStatus];


/**
 * 
 * @export
 * @interface WorkshopUserRoleCreateUpdateModel
 */
export interface WorkshopUserRoleCreateUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleCreateUpdateModel
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleCreateUpdateModel
     */
    'roleId': string;
}
/**
 * 
 * @export
 * @interface WorkshopUserRoleViewModel
 */
export interface WorkshopUserRoleViewModel {
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleViewModel
     */
    'workshopId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleViewModel
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleViewModel
     */
    'workshopName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserRoleViewModel
     */
    'roleName': string;
}
/**
 * 
 * @export
 * @interface WorkshopUserViewModel
 */
export interface WorkshopUserViewModel {
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkshopUserViewModel
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'cellNumber': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserViewModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkshopUserViewModel
     */
    'isVerified'?: boolean | null;
}
/**
 * 
 * @export
 * @interface WorkshopUserWorkshopViewModel
 */
export interface WorkshopUserWorkshopViewModel {
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserWorkshopViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserWorkshopViewModel
     */
    'roleName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserWorkshopViewModel
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopUserWorkshopViewModel
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface WorkshopViewModel
 */
export interface WorkshopViewModel {
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'name': string;
    /**
     * 
     * @type {WorkshopStatus}
     * @memberof WorkshopViewModel
     */
    'workshopStatusId': WorkshopStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'advanceUserEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'skynamoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'relativeImageUrl'?: string | null;
    /**
     * 
     * @type {Array<WorkshopUserWorkshopViewModel>}
     * @memberof WorkshopViewModel
     */
    'workshopUsers': Array<WorkshopUserWorkshopViewModel>;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'updatedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkshopViewModel
     */
    'activatedDate'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConfirmEmailDto} [confirmEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost: async (confirmEmailDto?: ConfirmEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost: async (emailAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost: async (loginDto?: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authenticationToken] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshPost: async (authenticationToken?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (authenticationToken !== undefined) {
                localVarQueryParameter['authenticationToken'] = authenticationToken;
            }

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenResultDto} [tokenResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost: async (tokenResultDto?: TokenResultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/RefreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} [registerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost: async (registerDto?: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [passwordResetToken] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost: async (emailAddress?: string, passwordResetToken?: string, newPassword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Account/ResetForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (passwordResetToken !== undefined) {
                localVarQueryParameter['passwordResetToken'] = passwordResetToken;
            }

            if (newPassword !== undefined) {
                localVarQueryParameter['newPassword'] = newPassword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConfirmEmailDto} [confirmEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountConfirmEmailPost(confirmEmailDto?: ConfirmEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountConfirmEmailPost(confirmEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountForgotPasswordPost(emailAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountForgotPasswordPost(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLoginPost(loginDto?: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLoginPost(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [authenticationToken] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRefreshPost(authenticationToken?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRefreshPost(authenticationToken, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TokenResultDto} [tokenResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRefreshTokenPost(tokenResultDto?: TokenResultDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRefreshTokenPost(tokenResultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterDto} [registerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountRegisterPost(registerDto?: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountRegisterPost(registerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [passwordResetToken] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountResetForgotPasswordPost(emailAddress?: string, passwordResetToken?: string, newPassword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {ConfirmEmailDto} [confirmEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountConfirmEmailPost(confirmEmailDto?: ConfirmEmailDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountConfirmEmailPost(confirmEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountForgotPasswordPost(emailAddress?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(loginDto?: LoginDto, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountLoginPost(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [authenticationToken] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshPost(authenticationToken?: string, refreshToken?: string, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountRefreshPost(authenticationToken, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TokenResultDto} [tokenResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenPost(tokenResultDto?: TokenResultDto, options?: any): AxiosPromise<TokenResultDto> {
            return localVarFp.apiAccountRefreshTokenPost(tokenResultDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} [registerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(registerDto?: RegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountRegisterPost(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [emailAddress] 
         * @param {string} [passwordResetToken] 
         * @param {string} [newPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetForgotPasswordPost(emailAddress?: string, passwordResetToken?: string, newPassword?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {ConfirmEmailDto} [confirmEmailDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountConfirmEmailPost(confirmEmailDto?: ConfirmEmailDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountConfirmEmailPost(confirmEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [emailAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountForgotPasswordPost(emailAddress?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountForgotPasswordPost(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLoginPost(loginDto?: LoginDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLoginPost(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLogoutPost(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [authenticationToken] 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRefreshPost(authenticationToken?: string, refreshToken?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRefreshPost(authenticationToken, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TokenResultDto} [tokenResultDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRefreshTokenPost(tokenResultDto?: TokenResultDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRefreshTokenPost(tokenResultDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} [registerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRegisterPost(registerDto?: RegisterDto, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountRegisterPost(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [emailAddress] 
     * @param {string} [passwordResetToken] 
     * @param {string} [newPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResetForgotPasswordPost(emailAddress?: string, passwordResetToken?: string, newPassword?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountResetForgotPasswordPost(emailAddress, passwordResetToken, newPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdvanceApi - axios parameter creator
 * @export
 */
export const AdvanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [advanceUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceGet: async (advanceUserEmail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/advance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (advanceUserEmail !== undefined) {
                localVarQueryParameter['advanceUserEmail'] = advanceUserEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderCreatedCommand} [orderCreatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceOrderCreatedPost: async (orderCreatedCommand?: OrderCreatedCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/advance/order-created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCreatedCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterAccountCommand} [registerAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceRegisterAccountPost: async (registerAccountCommand?: RegisterAccountCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/advance/register-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerAccountCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvanceApi - functional programming interface
 * @export
 */
export const AdvanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [advanceUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdvanceGet(advanceUserEmail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvanceWorkshopViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdvanceGet(advanceUserEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderCreatedCommand} [orderCreatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdvanceOrderCreatedPost(orderCreatedCommand?: OrderCreatedCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdvanceOrderCreatedPost(orderCreatedCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterAccountCommand} [registerAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdvanceRegisterAccountPost(registerAccountCommand?: RegisterAccountCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdvanceRegisterAccountPost(registerAccountCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvanceApi - factory interface
 * @export
 */
export const AdvanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [advanceUserEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceGet(advanceUserEmail?: string, options?: any): AxiosPromise<AdvanceWorkshopViewModel> {
            return localVarFp.apiAdvanceGet(advanceUserEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderCreatedCommand} [orderCreatedCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceOrderCreatedPost(orderCreatedCommand?: OrderCreatedCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAdvanceOrderCreatedPost(orderCreatedCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterAccountCommand} [registerAccountCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdvanceRegisterAccountPost(registerAccountCommand?: RegisterAccountCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiAdvanceRegisterAccountPost(registerAccountCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdvanceApi - object-oriented interface
 * @export
 * @class AdvanceApi
 * @extends {BaseAPI}
 */
export class AdvanceApi extends BaseAPI {
    /**
     * 
     * @param {string} [advanceUserEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvanceApi
     */
    public apiAdvanceGet(advanceUserEmail?: string, options?: AxiosRequestConfig) {
        return AdvanceApiFp(this.configuration).apiAdvanceGet(advanceUserEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderCreatedCommand} [orderCreatedCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvanceApi
     */
    public apiAdvanceOrderCreatedPost(orderCreatedCommand?: OrderCreatedCommand, options?: AxiosRequestConfig) {
        return AdvanceApiFp(this.configuration).apiAdvanceOrderCreatedPost(orderCreatedCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterAccountCommand} [registerAccountCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvanceApi
     */
    public apiAdvanceRegisterAccountPost(registerAccountCommand?: RegisterAccountCommand, options?: AxiosRequestConfig) {
        return AdvanceApiFp(this.configuration).apiAdvanceRegisterAccountPost(registerAccountCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditLogsApi - axios parameter creator
 * @export
 */
export const AuditLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogGet: async (auditLogTypesId?: AuditTypes, workshopId?: string, searchText?: string, pageNumber?: number, pageSize?: number, sortDirection?: SortDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audit-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (auditLogTypesId !== undefined) {
                localVarQueryParameter['auditLogTypesId'] = auditLogTypesId;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAuditLogIdGet', 'id', id)
            const localVarPath = `/api/audit-log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogsApi - functional programming interface
 * @export
 */
export const AuditLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditLogGet(auditLogTypesId?: AuditTypes, workshopId?: string, searchText?: string, pageNumber?: number, pageSize?: number, sortDirection?: SortDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfAuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditLogGet(auditLogTypesId, workshopId, searchText, pageNumber, pageSize, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditLogIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditLogIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogsApi - factory interface
 * @export
 */
export const AuditLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogsApiFp(configuration)
    return {
        /**
         * 
         * @param {AuditTypes} [auditLogTypesId] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SortDirection} [sortDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogGet(auditLogTypesId?: AuditTypes, workshopId?: string, searchText?: string, pageNumber?: number, pageSize?: number, sortDirection?: SortDirection, options?: any): AxiosPromise<PagedResultOfAuditLogDto> {
            return localVarFp.apiAuditLogGet(auditLogTypesId, workshopId, searchText, pageNumber, pageSize, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditLogIdGet(id: string, options?: any): AxiosPromise<AuditLogDto> {
            return localVarFp.apiAuditLogIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogsApi - object-oriented interface
 * @export
 * @class AuditLogsApi
 * @extends {BaseAPI}
 */
export class AuditLogsApi extends BaseAPI {
    /**
     * 
     * @param {AuditTypes} [auditLogTypesId] 
     * @param {string} [workshopId] 
     * @param {string} [searchText] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {SortDirection} [sortDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiAuditLogGet(auditLogTypesId?: AuditTypes, workshopId?: string, searchText?: string, pageNumber?: number, pageSize?: number, sortDirection?: SortDirection, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiAuditLogGet(auditLogTypesId, workshopId, searchText, pageNumber, pageSize, sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogsApi
     */
    public apiAuditLogIdGet(id: string, options?: AxiosRequestConfig) {
        return AuditLogsApiFp(this.configuration).apiAuditLogIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulletinsApi - axios parameter creator
 * @export
 */
export const BulletinsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinGet: async (bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bulletin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bulletinTypesId !== undefined) {
                localVarQueryParameter['bulletinTypesId'] = bulletinTypesId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBulletinIdGet', 'id', id)
            const localVarPath = `/api/bulletin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulletinsApi - functional programming interface
 * @export
 */
export const BulletinsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulletinsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfBulletinDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinGet(bulletinTypesId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulletinDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulletinsApi - factory interface
 * @export
 */
export const BulletinsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulletinsApiFp(configuration)
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfBulletinDto> {
            return localVarFp.apiBulletinGet(bulletinTypesId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinIdGet(id: string, options?: any): AxiosPromise<BulletinDto> {
            return localVarFp.apiBulletinIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulletinsApi - object-oriented interface
 * @export
 * @class BulletinsApi
 * @extends {BaseAPI}
 */
export class BulletinsApi extends BaseAPI {
    /**
     * 
     * @param {BulletinType} [bulletinTypesId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsApi
     */
    public apiBulletinGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BulletinsApiFp(this.configuration).apiBulletinGet(bulletinTypesId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsApi
     */
    public apiBulletinIdGet(id: string, options?: AxiosRequestConfig) {
        return BulletinsApiFp(this.configuration).apiBulletinIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BulletinsAdminApi - axios parameter creator
 * @export
 */
export const BulletinsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminGet: async (bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bulletin/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bulletinTypesId !== undefined) {
                localVarQueryParameter['bulletinTypesId'] = bulletinTypesId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBulletinAdminIdGet', 'id', id)
            const localVarPath = `/api/bulletin/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulletinCommand} [updateBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminIdPut: async (id: string, updateBulletinCommand?: UpdateBulletinCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBulletinAdminIdPut', 'id', id)
            const localVarPath = `/api/bulletin/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBulletinCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBulletinCommand} [createBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminPost: async (createBulletinCommand?: CreateBulletinCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bulletin/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBulletinCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinsAdminIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiBulletinsAdminIdDelete', 'id', id)
            const localVarPath = `/api/bulletins/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulletinsAdminApi - functional programming interface
 * @export
 */
export const BulletinsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulletinsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinAdminGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfBulletinDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinAdminGet(bulletinTypesId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinAdminIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulletinDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinAdminIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulletinCommand} [updateBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinAdminIdPut(id: string, updateBulletinCommand?: UpdateBulletinCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinAdminIdPut(id, updateBulletinCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBulletinCommand} [createBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinAdminPost(createBulletinCommand?: CreateBulletinCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinAdminPost(createBulletinCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBulletinsAdminIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBulletinsAdminIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulletinsAdminApi - factory interface
 * @export
 */
export const BulletinsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulletinsAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {BulletinType} [bulletinTypesId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfBulletinDto> {
            return localVarFp.apiBulletinAdminGet(bulletinTypesId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminIdGet(id: string, options?: any): AxiosPromise<BulletinDto> {
            return localVarFp.apiBulletinAdminIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateBulletinCommand} [updateBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminIdPut(id: string, updateBulletinCommand?: UpdateBulletinCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiBulletinAdminIdPut(id, updateBulletinCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBulletinCommand} [createBulletinCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinAdminPost(createBulletinCommand?: CreateBulletinCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiBulletinAdminPost(createBulletinCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBulletinsAdminIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBulletinsAdminIdDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulletinsAdminApi - object-oriented interface
 * @export
 * @class BulletinsAdminApi
 * @extends {BaseAPI}
 */
export class BulletinsAdminApi extends BaseAPI {
    /**
     * 
     * @param {BulletinType} [bulletinTypesId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsAdminApi
     */
    public apiBulletinAdminGet(bulletinTypesId?: BulletinType, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return BulletinsAdminApiFp(this.configuration).apiBulletinAdminGet(bulletinTypesId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsAdminApi
     */
    public apiBulletinAdminIdGet(id: string, options?: AxiosRequestConfig) {
        return BulletinsAdminApiFp(this.configuration).apiBulletinAdminIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateBulletinCommand} [updateBulletinCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsAdminApi
     */
    public apiBulletinAdminIdPut(id: string, updateBulletinCommand?: UpdateBulletinCommand, options?: AxiosRequestConfig) {
        return BulletinsAdminApiFp(this.configuration).apiBulletinAdminIdPut(id, updateBulletinCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBulletinCommand} [createBulletinCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsAdminApi
     */
    public apiBulletinAdminPost(createBulletinCommand?: CreateBulletinCommand, options?: AxiosRequestConfig) {
        return BulletinsAdminApiFp(this.configuration).apiBulletinAdminPost(createBulletinCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulletinsAdminApi
     */
    public apiBulletinsAdminIdDelete(id: string, options?: AxiosRequestConfig) {
        return BulletinsAdminApiFp(this.configuration).apiBulletinsAdminIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet: async (relativePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesViewRelativePathGet', 'relativePath', relativePath)
            const localVarPath = `/files/view/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet: async (relativePath: string, width: number, height: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relativePath' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'relativePath', relativePath)
            // verify required parameter 'width' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'width', width)
            // verify required parameter 'height' is not null or undefined
            assertParamExists('filesWidthHeightRelativePathGet', 'height', height)
            const localVarPath = `/files/{width}/{height}/{relativePath}`
                .replace(`{${"relativePath"}}`, encodeURIComponent(String(relativePath)))
                .replace(`{${"width"}}`, encodeURIComponent(String(width)))
                .replace(`{${"height"}}`, encodeURIComponent(String(height)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilePath>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesViewRelativePathGet(relativePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesWidthHeightRelativePathGet(relativePath, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(file?: File, options?: any): AxiosPromise<FilePath> {
            return localVarFp.filesPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesViewRelativePathGet(relativePath: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesViewRelativePathGet(relativePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} relativePath 
         * @param {number} width 
         * @param {number} height 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: any): AxiosPromise<string> {
            return localVarFp.filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(file?: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesViewRelativePathGet(relativePath: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesViewRelativePathGet(relativePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} relativePath 
     * @param {number} width 
     * @param {number} height 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesWidthHeightRelativePathGet(relativePath: string, width: number, height: number, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesWidthHeightRelativePathGet(relativePath, width, height, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportsApi - axios parameter creator
 * @export
 */
export const ImportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImportGet: async (pageNo?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportsApi - functional programming interface
 * @export
 */
export const ImportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiImportGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfImportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiImportGet(pageNo, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportsApi - factory interface
 * @export
 */
export const ImportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiImportGet(pageNo?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfImportDto> {
            return localVarFp.apiImportGet(pageNo, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportsApi - object-oriented interface
 * @export
 * @class ImportsApi
 * @extends {BaseAPI}
 */
export class ImportsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportsApi
     */
    public apiImportGet(pageNo?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ImportsApiFp(this.configuration).apiImportGet(pageNo, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointTransactionsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointTransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsBalanceGet: async (workshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-point-transactions/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsGet: async (pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-point-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (createdStartDate !== undefined) {
                localVarQueryParameter['createdStartDate'] = (createdStartDate as any instanceof Date) ?
                    (createdStartDate as any).toISOString() :
                    createdStartDate;
            }

            if (createdEndDate !== undefined) {
                localVarQueryParameter['createdEndDate'] = (createdEndDate as any instanceof Date) ?
                    (createdEndDate as any).toISOString() :
                    createdEndDate;
            }

            if (expiryStartDate !== undefined) {
                localVarQueryParameter['expiryStartDate'] = (expiryStartDate as any instanceof Date) ?
                    (expiryStartDate as any).toISOString() :
                    expiryStartDate;
            }

            if (expiryEndDate !== undefined) {
                localVarQueryParameter['expiryEndDate'] = (expiryEndDate as any instanceof Date) ?
                    (expiryEndDate as any).toISOString() :
                    expiryEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointTransactionsIdGet', 'id', id)
            const localVarPath = `/api/loyalty-point-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTransactionTrackingNumber} [updateLoyaltyPointsTransactionTrackingNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsIdPut: async (id: string, updateLoyaltyPointsTransactionTrackingNumber?: UpdateLoyaltyPointsTransactionTrackingNumber, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointTransactionsIdPut', 'id', id)
            const localVarPath = `/api/loyalty-point-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyPointsTransactionTrackingNumber, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLoyaltyPointsManualTransactionCommand} [createLoyaltyPointsManualTransactionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsPost: async (createLoyaltyPointsManualTransactionCommand?: CreateLoyaltyPointsManualTransactionCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-point-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLoyaltyPointsManualTransactionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsReportGet: async (pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-point-transactions/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (createdStartDate !== undefined) {
                localVarQueryParameter['createdStartDate'] = (createdStartDate as any instanceof Date) ?
                    (createdStartDate as any).toISOString() :
                    createdStartDate;
            }

            if (createdEndDate !== undefined) {
                localVarQueryParameter['createdEndDate'] = (createdEndDate as any instanceof Date) ?
                    (createdEndDate as any).toISOString() :
                    createdEndDate;
            }

            if (expiryStartDate !== undefined) {
                localVarQueryParameter['expiryStartDate'] = (expiryStartDate as any instanceof Date) ?
                    (expiryStartDate as any).toISOString() :
                    expiryStartDate;
            }

            if (expiryEndDate !== undefined) {
                localVarQueryParameter['expiryEndDate'] = (expiryEndDate as any instanceof Date) ?
                    (expiryEndDate as any).toISOString() :
                    expiryEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointTransactionsApi - functional programming interface
 * @export
 */
export const LoyaltyPointTransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointTransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsBalanceGet(workshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopBalanceViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsBalanceGet(workshopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfLoyaltyPointTransactionListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointsManualTransactionViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTransactionTrackingNumber} [updateLoyaltyPointsTransactionTrackingNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsIdPut(id: string, updateLoyaltyPointsTransactionTrackingNumber?: UpdateLoyaltyPointsTransactionTrackingNumber, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsIdPut(id, updateLoyaltyPointsTransactionTrackingNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateLoyaltyPointsManualTransactionCommand} [createLoyaltyPointsManualTransactionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand?: CreateLoyaltyPointsManualTransactionCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointTransactionsReportGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfLoyaltyPointTransactionListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointTransactionsReportGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointTransactionsApi - factory interface
 * @export
 */
export const LoyaltyPointTransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointTransactionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsBalanceGet(workshopId?: string, options?: any): AxiosPromise<WorkshopBalanceViewModel> {
            return localVarFp.apiLoyaltyPointTransactionsBalanceGet(workshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: any): AxiosPromise<PagedResultOfLoyaltyPointTransactionListViewModel> {
            return localVarFp.apiLoyaltyPointTransactionsGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsIdGet(id: string, options?: any): AxiosPromise<LoyaltyPointsManualTransactionViewModel> {
            return localVarFp.apiLoyaltyPointTransactionsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTransactionTrackingNumber} [updateLoyaltyPointsTransactionTrackingNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsIdPut(id: string, updateLoyaltyPointsTransactionTrackingNumber?: UpdateLoyaltyPointsTransactionTrackingNumber, options?: any): AxiosPromise<void> {
            return localVarFp.apiLoyaltyPointTransactionsIdPut(id, updateLoyaltyPointsTransactionTrackingNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateLoyaltyPointsManualTransactionCommand} [createLoyaltyPointsManualTransactionCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand?: CreateLoyaltyPointsManualTransactionCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {string} [createdStartDate] 
         * @param {string} [createdEndDate] 
         * @param {string} [expiryStartDate] 
         * @param {string} [expiryEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointTransactionsReportGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: any): AxiosPromise<PagedResultOfLoyaltyPointTransactionListViewModel> {
            return localVarFp.apiLoyaltyPointTransactionsReportGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointTransactionsApi - object-oriented interface
 * @export
 * @class LoyaltyPointTransactionsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointTransactionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [workshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsBalanceGet(workshopId?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsBalanceGet(workshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {string} [workshopId] 
     * @param {string} [createdStartDate] 
     * @param {string} [createdEndDate] 
     * @param {string} [expiryStartDate] 
     * @param {string} [expiryEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsIdGet(id: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLoyaltyPointsTransactionTrackingNumber} [updateLoyaltyPointsTransactionTrackingNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsIdPut(id: string, updateLoyaltyPointsTransactionTrackingNumber?: UpdateLoyaltyPointsTransactionTrackingNumber, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsIdPut(id, updateLoyaltyPointsTransactionTrackingNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateLoyaltyPointsManualTransactionCommand} [createLoyaltyPointsManualTransactionCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand?: CreateLoyaltyPointsManualTransactionCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsPost(createLoyaltyPointsManualTransactionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {string} [workshopId] 
     * @param {string} [createdStartDate] 
     * @param {string} [createdEndDate] 
     * @param {string} [expiryStartDate] 
     * @param {string} [expiryEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointTransactionsApi
     */
    public apiLoyaltyPointTransactionsReportGet(pageNumber?: number, pageSize?: number, searchText?: string, workshopId?: string, createdStartDate?: string, createdEndDate?: string, expiryStartDate?: string, expiryEndDate?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointTransactionsApiFp(this.configuration).apiLoyaltyPointTransactionsReportGet(pageNumber, pageSize, searchText, workshopId, createdStartDate, createdEndDate, expiryStartDate, expiryEndDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointsClaimsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointsClaimsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isFullMatch] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsGet: async (pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, isFullMatch?: boolean, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (statusId !== undefined) {
                localVarQueryParameter['statusId'] = statusId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (isFullMatch !== undefined) {
                localVarQueryParameter['isFullMatch'] = isFullMatch;
            }

            if (dateSubmittedStartDate !== undefined) {
                localVarQueryParameter['dateSubmittedStartDate'] = (dateSubmittedStartDate as any instanceof Date) ?
                    (dateSubmittedStartDate as any).toISOString() :
                    dateSubmittedStartDate;
            }

            if (dateSubmittedEndDate !== undefined) {
                localVarQueryParameter['dateSubmittedEndDate'] = (dateSubmittedEndDate as any instanceof Date) ?
                    (dateSubmittedEndDate as any).toISOString() :
                    dateSubmittedEndDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointsClaimsIdGet', 'id', id)
            const localVarPath = `/api/loyalty-points-claims/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ResubmitLoyaltyPointsClaimCommand} [resubmitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsIdPut: async (id: string, resubmitLoyaltyPointsClaimCommand?: ResubmitLoyaltyPointsClaimCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointsClaimsIdPut', 'id', id)
            const localVarPath = `/api/loyalty-points-claims/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resubmitLoyaltyPointsClaimCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimCommand} [submitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsPost: async (submitLoyaltyPointsClaimCommand?: SubmitLoyaltyPointsClaimCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitLoyaltyPointsClaimCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointsClaimsApi - functional programming interface
 * @export
 */
export const LoyaltyPointsClaimsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointsClaimsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isFullMatch] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, isFullMatch?: boolean, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfLoyaltyPointClaimListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, isFullMatch, dateSubmittedStartDate, dateSubmittedEndDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointClaimViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ResubmitLoyaltyPointsClaimCommand} [resubmitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsIdPut(id: string, resubmitLoyaltyPointsClaimCommand?: ResubmitLoyaltyPointsClaimCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsIdPut(id, resubmitLoyaltyPointsClaimCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimCommand} [submitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand?: SubmitLoyaltyPointsClaimCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointsClaimsApi - factory interface
 * @export
 */
export const LoyaltyPointsClaimsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointsClaimsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {boolean} [isFullMatch] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, isFullMatch?: boolean, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, options?: any): AxiosPromise<PagedResultOfLoyaltyPointClaimListViewModel> {
            return localVarFp.apiLoyaltyPointsClaimsGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, isFullMatch, dateSubmittedStartDate, dateSubmittedEndDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsIdGet(id: string, options?: any): AxiosPromise<LoyaltyPointClaimViewModel> {
            return localVarFp.apiLoyaltyPointsClaimsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ResubmitLoyaltyPointsClaimCommand} [resubmitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsIdPut(id: string, resubmitLoyaltyPointsClaimCommand?: ResubmitLoyaltyPointsClaimCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLoyaltyPointsClaimsIdPut(id, resubmitLoyaltyPointsClaimCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimCommand} [submitLoyaltyPointsClaimCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand?: SubmitLoyaltyPointsClaimCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointsClaimsApi - object-oriented interface
 * @export
 * @class LoyaltyPointsClaimsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointsClaimsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {LoyaltyPointClaimStatus} [statusId] 
     * @param {string} [searchText] 
     * @param {string} [workshopId] 
     * @param {SortDirection} [sortDirection] 
     * @param {boolean} [isFullMatch] 
     * @param {string} [dateSubmittedStartDate] 
     * @param {string} [dateSubmittedEndDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsApi
     */
    public apiLoyaltyPointsClaimsGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, isFullMatch?: boolean, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsApiFp(this.configuration).apiLoyaltyPointsClaimsGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, isFullMatch, dateSubmittedStartDate, dateSubmittedEndDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsApi
     */
    public apiLoyaltyPointsClaimsIdGet(id: string, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsApiFp(this.configuration).apiLoyaltyPointsClaimsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ResubmitLoyaltyPointsClaimCommand} [resubmitLoyaltyPointsClaimCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsApi
     */
    public apiLoyaltyPointsClaimsIdPut(id: string, resubmitLoyaltyPointsClaimCommand?: ResubmitLoyaltyPointsClaimCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsApiFp(this.configuration).apiLoyaltyPointsClaimsIdPut(id, resubmitLoyaltyPointsClaimCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitLoyaltyPointsClaimCommand} [submitLoyaltyPointsClaimCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsApi
     */
    public apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand?: SubmitLoyaltyPointsClaimCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsApiFp(this.configuration).apiLoyaltyPointsClaimsPost(submitLoyaltyPointsClaimCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointsClaimsAdminApi - axios parameter creator
 * @export
 */
export const LoyaltyPointsClaimsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BulkReviewLoyaltyPointsClaimAdminCommand} [bulkReviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut: async (bulkReviewLoyaltyPointsClaimAdminCommand?: BulkReviewLoyaltyPointsClaimAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-claims/admin/bulk-review-loyalty-points-claim-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkReviewLoyaltyPointsClaimAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {boolean} [isFullMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminGet: async (pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, isFullMatch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-claims/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (statusId !== undefined) {
                localVarQueryParameter['statusId'] = statusId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (dateSubmittedStartDate !== undefined) {
                localVarQueryParameter['dateSubmittedStartDate'] = (dateSubmittedStartDate as any instanceof Date) ?
                    (dateSubmittedStartDate as any).toISOString() :
                    dateSubmittedStartDate;
            }

            if (dateSubmittedEndDate !== undefined) {
                localVarQueryParameter['dateSubmittedEndDate'] = (dateSubmittedEndDate as any instanceof Date) ?
                    (dateSubmittedEndDate as any).toISOString() :
                    dateSubmittedEndDate;
            }

            if (isFullMatch !== undefined) {
                localVarQueryParameter['isFullMatch'] = isFullMatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ReviewLoyaltyPointsClaimAdminCommand} [reviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminIdPut: async (id: string, reviewLoyaltyPointsClaimAdminCommand?: ReviewLoyaltyPointsClaimAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointsClaimsAdminIdPut', 'id', id)
            const localVarPath = `/api/loyalty-points-claims/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewLoyaltyPointsClaimAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimAdminCommand} [submitLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminPost: async (submitLoyaltyPointsClaimAdminCommand?: SubmitLoyaltyPointsClaimAdminCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-claims/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitLoyaltyPointsClaimAdminCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointsClaimsAdminApi - functional programming interface
 * @export
 */
export const LoyaltyPointsClaimsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointsClaimsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BulkReviewLoyaltyPointsClaimAdminCommand} [bulkReviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand?: BulkReviewLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {boolean} [isFullMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsAdminGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, isFullMatch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfLoyaltyPointClaimListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsAdminGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, dateSubmittedStartDate, dateSubmittedEndDate, isFullMatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ReviewLoyaltyPointsClaimAdminCommand} [reviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsAdminIdPut(id: string, reviewLoyaltyPointsClaimAdminCommand?: ReviewLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsAdminIdPut(id, reviewLoyaltyPointsClaimAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimAdminCommand} [submitLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand?: SubmitLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointsClaimsAdminApi - factory interface
 * @export
 */
export const LoyaltyPointsClaimsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointsClaimsAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {BulkReviewLoyaltyPointsClaimAdminCommand} [bulkReviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand?: BulkReviewLoyaltyPointsClaimAdminCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {LoyaltyPointClaimStatus} [statusId] 
         * @param {string} [searchText] 
         * @param {string} [workshopId] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [dateSubmittedStartDate] 
         * @param {string} [dateSubmittedEndDate] 
         * @param {boolean} [isFullMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, isFullMatch?: boolean, options?: any): AxiosPromise<PagedResultOfLoyaltyPointClaimListViewModel> {
            return localVarFp.apiLoyaltyPointsClaimsAdminGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, dateSubmittedStartDate, dateSubmittedEndDate, isFullMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ReviewLoyaltyPointsClaimAdminCommand} [reviewLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminIdPut(id: string, reviewLoyaltyPointsClaimAdminCommand?: ReviewLoyaltyPointsClaimAdminCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLoyaltyPointsClaimsAdminIdPut(id, reviewLoyaltyPointsClaimAdminCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubmitLoyaltyPointsClaimAdminCommand} [submitLoyaltyPointsClaimAdminCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand?: SubmitLoyaltyPointsClaimAdminCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointsClaimsAdminApi - object-oriented interface
 * @export
 * @class LoyaltyPointsClaimsAdminApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointsClaimsAdminApi extends BaseAPI {
    /**
     * 
     * @param {BulkReviewLoyaltyPointsClaimAdminCommand} [bulkReviewLoyaltyPointsClaimAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsAdminApi
     */
    public apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand?: BulkReviewLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsAdminApiFp(this.configuration).apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(bulkReviewLoyaltyPointsClaimAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {LoyaltyPointClaimStatus} [statusId] 
     * @param {string} [searchText] 
     * @param {string} [workshopId] 
     * @param {SortDirection} [sortDirection] 
     * @param {string} [dateSubmittedStartDate] 
     * @param {string} [dateSubmittedEndDate] 
     * @param {boolean} [isFullMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsAdminApi
     */
    public apiLoyaltyPointsClaimsAdminGet(pageNumber?: number, pageSize?: number, statusId?: LoyaltyPointClaimStatus, searchText?: string, workshopId?: string, sortDirection?: SortDirection, dateSubmittedStartDate?: string, dateSubmittedEndDate?: string, isFullMatch?: boolean, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsAdminApiFp(this.configuration).apiLoyaltyPointsClaimsAdminGet(pageNumber, pageSize, statusId, searchText, workshopId, sortDirection, dateSubmittedStartDate, dateSubmittedEndDate, isFullMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ReviewLoyaltyPointsClaimAdminCommand} [reviewLoyaltyPointsClaimAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsAdminApi
     */
    public apiLoyaltyPointsClaimsAdminIdPut(id: string, reviewLoyaltyPointsClaimAdminCommand?: ReviewLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsAdminApiFp(this.configuration).apiLoyaltyPointsClaimsAdminIdPut(id, reviewLoyaltyPointsClaimAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubmitLoyaltyPointsClaimAdminCommand} [submitLoyaltyPointsClaimAdminCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsClaimsAdminApi
     */
    public apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand?: SubmitLoyaltyPointsClaimAdminCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsClaimsAdminApiFp(this.configuration).apiLoyaltyPointsClaimsAdminPost(submitLoyaltyPointsClaimAdminCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointsTranactionReturnsApi - axios parameter creator
 * @export
 */
export const LoyaltyPointsTranactionReturnsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SubmitLoyaltyPointsReturnCommand} [submitLoyaltyPointsReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnPost: async (submitLoyaltyPointsReturnCommand?: SubmitLoyaltyPointsReturnCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitLoyaltyPointsReturnCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointsTranactionReturnsApi - functional programming interface
 * @export
 */
export const LoyaltyPointsTranactionReturnsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointsTranactionReturnsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SubmitLoyaltyPointsReturnCommand} [submitLoyaltyPointsReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand?: SubmitLoyaltyPointsReturnCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointsTranactionReturnsApi - factory interface
 * @export
 */
export const LoyaltyPointsTranactionReturnsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointsTranactionReturnsApiFp(configuration)
    return {
        /**
         * 
         * @param {SubmitLoyaltyPointsReturnCommand} [submitLoyaltyPointsReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand?: SubmitLoyaltyPointsReturnCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointsTranactionReturnsApi - object-oriented interface
 * @export
 * @class LoyaltyPointsTranactionReturnsApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointsTranactionReturnsApi extends BaseAPI {
    /**
     * 
     * @param {SubmitLoyaltyPointsReturnCommand} [submitLoyaltyPointsReturnCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsTranactionReturnsApi
     */
    public apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand?: SubmitLoyaltyPointsReturnCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsTranactionReturnsApiFp(this.configuration).apiLoyaltyPointsReturnPost(submitLoyaltyPointsReturnCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoyaltyPointsTranactionReturnsAdminApi - axios parameter creator
 * @export
 */
export const LoyaltyPointsTranactionReturnsAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminGet: async (pageNumber?: number, pageSize?: number, workshopId?: string, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loyalty-points-returns/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointsReturnsAdminIdGet', 'id', id)
            const localVarPath = `/api/loyalty-points-returns/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTranactionReturnCommand} [updateLoyaltyPointsTranactionReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminIdPut: async (id: string, updateLoyaltyPointsTranactionReturnCommand?: UpdateLoyaltyPointsTranactionReturnCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLoyaltyPointsReturnsAdminIdPut', 'id', id)
            const localVarPath = `/api/loyalty-points-returns/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLoyaltyPointsTranactionReturnCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoyaltyPointsTranactionReturnsAdminApi - functional programming interface
 * @export
 */
export const LoyaltyPointsTranactionReturnsAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoyaltyPointsTranactionReturnsAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsReturnsAdminGet(pageNumber?: number, pageSize?: number, workshopId?: string, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfLoyaltyPointsTranactionReturnDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsReturnsAdminGet(pageNumber, pageSize, workshopId, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsReturnsAdminIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoyaltyPointsTranactionReturnDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsReturnsAdminIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTranactionReturnCommand} [updateLoyaltyPointsTranactionReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoyaltyPointsReturnsAdminIdPut(id: string, updateLoyaltyPointsTranactionReturnCommand?: UpdateLoyaltyPointsTranactionReturnCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoyaltyPointsReturnsAdminIdPut(id, updateLoyaltyPointsTranactionReturnCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoyaltyPointsTranactionReturnsAdminApi - factory interface
 * @export
 */
export const LoyaltyPointsTranactionReturnsAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoyaltyPointsTranactionReturnsAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [workshopId] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminGet(pageNumber?: number, pageSize?: number, workshopId?: string, searchText?: string, options?: any): AxiosPromise<PagedResultOfLoyaltyPointsTranactionReturnDto> {
            return localVarFp.apiLoyaltyPointsReturnsAdminGet(pageNumber, pageSize, workshopId, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminIdGet(id: string, options?: any): AxiosPromise<LoyaltyPointsTranactionReturnDto> {
            return localVarFp.apiLoyaltyPointsReturnsAdminIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateLoyaltyPointsTranactionReturnCommand} [updateLoyaltyPointsTranactionReturnCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoyaltyPointsReturnsAdminIdPut(id: string, updateLoyaltyPointsTranactionReturnCommand?: UpdateLoyaltyPointsTranactionReturnCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiLoyaltyPointsReturnsAdminIdPut(id, updateLoyaltyPointsTranactionReturnCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoyaltyPointsTranactionReturnsAdminApi - object-oriented interface
 * @export
 * @class LoyaltyPointsTranactionReturnsAdminApi
 * @extends {BaseAPI}
 */
export class LoyaltyPointsTranactionReturnsAdminApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [workshopId] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsTranactionReturnsAdminApi
     */
    public apiLoyaltyPointsReturnsAdminGet(pageNumber?: number, pageSize?: number, workshopId?: string, searchText?: string, options?: AxiosRequestConfig) {
        return LoyaltyPointsTranactionReturnsAdminApiFp(this.configuration).apiLoyaltyPointsReturnsAdminGet(pageNumber, pageSize, workshopId, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsTranactionReturnsAdminApi
     */
    public apiLoyaltyPointsReturnsAdminIdGet(id: string, options?: AxiosRequestConfig) {
        return LoyaltyPointsTranactionReturnsAdminApiFp(this.configuration).apiLoyaltyPointsReturnsAdminIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateLoyaltyPointsTranactionReturnCommand} [updateLoyaltyPointsTranactionReturnCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoyaltyPointsTranactionReturnsAdminApi
     */
    public apiLoyaltyPointsReturnsAdminIdPut(id: string, updateLoyaltyPointsTranactionReturnCommand?: UpdateLoyaltyPointsTranactionReturnCommand, options?: AxiosRequestConfig) {
        return LoyaltyPointsTranactionReturnsAdminApiFp(this.configuration).apiLoyaltyPointsReturnsAdminIdPut(id, updateLoyaltyPointsTranactionReturnCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet: async (pageNumber?: number, pageSize?: number, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdDelete', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdGet', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdPut: async (id: string, updateProductCommand?: UpdateProductCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductsIdPut', 'id', id)
            const localVarPath = `/api/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkImportProductsCommand} [bulkImportProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImportPost: async (bulkImportProductsCommand?: BulkImportProductsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkImportProductsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost: async (createProductCommand?: CreateProductCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkImportProductsSkuAliasesCommand} [bulkImportProductsSkuAliasesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsSkuAliasesImportPost: async (bulkImportProductsSkuAliasesCommand?: BulkImportProductsSkuAliasesCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/skuAliases/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkImportProductsSkuAliasesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsValueGet: async (id?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/value`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfProductListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsGet(pageNumber, pageSize, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsIdPut(id: string, updateProductCommand?: UpdateProductCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsIdPut(id, updateProductCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkImportProductsCommand} [bulkImportProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsImportPost(bulkImportProductsCommand?: BulkImportProductsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsImportPost(bulkImportProductsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsPost(createProductCommand?: CreateProductCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsPost(createProductCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkImportProductsSkuAliasesCommand} [bulkImportProductsSkuAliasesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand?: BulkImportProductsSkuAliasesCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductsValueGet(id?: string, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductsValueGet(id, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: any): AxiosPromise<PagedResultOfProductListViewModel> {
            return localVarFp.apiProductsGet(pageNumber, pageSize, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdGet(id: string, options?: any): AxiosPromise<ProductViewModel> {
            return localVarFp.apiProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProductCommand} [updateProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsIdPut(id: string, updateProductCommand?: UpdateProductCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiProductsIdPut(id, updateProductCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkImportProductsCommand} [bulkImportProductsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsImportPost(bulkImportProductsCommand?: BulkImportProductsCommand, options?: any): AxiosPromise<JsonResponseOfListOfString> {
            return localVarFp.apiProductsImportPost(bulkImportProductsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProductCommand} [createProductCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsPost(createProductCommand?: CreateProductCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiProductsPost(createProductCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkImportProductsSkuAliasesCommand} [bulkImportProductsSkuAliasesCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand?: BulkImportProductsSkuAliasesCommand, options?: any): AxiosPromise<JsonResponseOfListOfString> {
            return localVarFp.apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductsValueGet(id?: string, date?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiProductsValueGet(id, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsGet(pageNumber, pageSize, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateProductCommand} [updateProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsIdPut(id: string, updateProductCommand?: UpdateProductCommand, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsIdPut(id, updateProductCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkImportProductsCommand} [bulkImportProductsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsImportPost(bulkImportProductsCommand?: BulkImportProductsCommand, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsImportPost(bulkImportProductsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProductCommand} [createProductCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsPost(createProductCommand?: CreateProductCommand, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsPost(createProductCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkImportProductsSkuAliasesCommand} [bulkImportProductsSkuAliasesCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand?: BulkImportProductsSkuAliasesCommand, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsSkuAliasesImportPost(bulkImportProductsSkuAliasesCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiProductsValueGet(id?: string, date?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiProductsValueGet(id, date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClaimsGet: async (pageNumber?: number, pageSize?: number, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/claims`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {boolean} [isWorkshopRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet: async (pageNumber?: number, pageSize?: number, searchText?: string, isWorkshopRole?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (isWorkshopRole !== undefined) {
                localVarQueryParameter['isWorkshopRole'] = isWorkshopRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRolesIdDelete', 'id', id)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRolesIdGet', 'id', id)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleCommand} [updateRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdPut: async (id: string, updateRoleCommand?: UpdateRoleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRolesIdPut', 'id', id)
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRoleCommand} [createRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPost: async (createRoleCommand?: CreateRoleCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClaimsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfClaimListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClaimsGet(pageNumber, pageSize, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {boolean} [isWorkshopRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesGet(pageNumber?: number, pageSize?: number, searchText?: string, isWorkshopRole?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfRoleListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesGet(pageNumber, pageSize, searchText, isWorkshopRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleCommand} [updateRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesIdPut(id: string, updateRoleCommand?: UpdateRoleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesIdPut(id, updateRoleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRoleCommand} [createRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRolesPost(createRoleCommand?: CreateRoleCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRolesPost(createRoleCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClaimsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: any): AxiosPromise<PagedResultOfClaimListViewModel> {
            return localVarFp.apiClaimsGet(pageNumber, pageSize, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {boolean} [isWorkshopRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesGet(pageNumber?: number, pageSize?: number, searchText?: string, isWorkshopRole?: boolean, options?: any): AxiosPromise<PagedResultOfRoleListViewModel> {
            return localVarFp.apiRolesGet(pageNumber, pageSize, searchText, isWorkshopRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdGet(id: string, options?: any): AxiosPromise<RoleViewModel> {
            return localVarFp.apiRolesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleCommand} [updateRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesIdPut(id: string, updateRoleCommand?: UpdateRoleCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiRolesIdPut(id, updateRoleCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRoleCommand} [createRoleCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRolesPost(createRoleCommand?: CreateRoleCommand, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiRolesPost(createRoleCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiClaimsGet(pageNumber?: number, pageSize?: number, searchText?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiClaimsGet(pageNumber, pageSize, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {boolean} [isWorkshopRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesGet(pageNumber?: number, pageSize?: number, searchText?: string, isWorkshopRole?: boolean, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesGet(pageNumber, pageSize, searchText, isWorkshopRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesIdDelete(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesIdGet(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRoleCommand} [updateRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesIdPut(id: string, updateRoleCommand?: UpdateRoleCommand, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesIdPut(id, updateRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateRoleCommand} [createRoleCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public apiRolesPost(createRoleCommand?: CreateRoleCommand, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).apiRolesPost(createRoleCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {boolean} [isWorkshopUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, isWorkshopUser?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds;
            }

            if (isWorkshopUser !== undefined) {
                localVarQueryParameter['isWorkshopUser'] = isWorkshopUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdGet', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut: async (id: string, updateUserCommand?: UpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUsersIdPut', 'id', id)
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost: async (createUserCommand?: CreateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {boolean} [isWorkshopUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, isWorkshopUser?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfUserViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(pageNumber, pageSize, searchText, roleIds, isWorkshopUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersIdPut(id, updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPost(createUserCommand?: CreateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPost(createUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {boolean} [isWorkshopUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, isWorkshopUser?: boolean, options?: any): AxiosPromise<PagedResultOfUserViewModel> {
            return localVarFp.apiUsersGet(pageNumber, pageSize, searchText, roleIds, isWorkshopUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdGet(id: string, options?: any): AxiosPromise<UserViewModel> {
            return localVarFp.apiUsersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiUsersIdPut(id, updateUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(createUserCommand?: CreateUserCommand, options?: any): AxiosPromise<JsonResponseOfString> {
            return localVarFp.apiUsersPost(createUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {Array<string>} [roleIds] 
     * @param {boolean} [isWorkshopUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, isWorkshopUser?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersGet(pageNumber, pageSize, searchText, roleIds, isWorkshopUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserCommand} [updateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersIdPut(id, updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserCommand} [createUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPost(createUserCommand?: CreateUserCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPost(createUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkshopUsersApi - axios parameter creator
 * @export
 */
export const WorkshopUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersGet: async (pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, workshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshopusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (roleIds) {
                localVarQueryParameter['roleIds'] = roleIds;
            }

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersIdGet: async (id: string, workshopId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkshopusersIdGet', 'id', id)
            const localVarPath = `/api/workshopusers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workshopId !== undefined) {
                localVarQueryParameter['workshopId'] = workshopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWorkshopUserCommand} [updateWorkshopUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersPut: async (updateWorkshopUserCommand?: UpdateWorkshopUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshopusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkshopUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkshopUsersApi - functional programming interface
 * @export
 */
export const WorkshopUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkshopUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopusersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, workshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfWorkshopUserViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopusersGet(pageNumber, pageSize, searchText, roleIds, workshopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopusersIdGet(id: string, workshopId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopUserViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopusersIdGet(id, workshopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWorkshopUserCommand} [updateWorkshopUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopusersPut(updateWorkshopUserCommand?: UpdateWorkshopUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopusersPut(updateWorkshopUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkshopUsersApi - factory interface
 * @export
 */
export const WorkshopUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkshopUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {Array<string>} [roleIds] 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, workshopId?: string, options?: any): AxiosPromise<PagedResultOfWorkshopUserViewModel> {
            return localVarFp.apiWorkshopusersGet(pageNumber, pageSize, searchText, roleIds, workshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [workshopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersIdGet(id: string, workshopId?: string, options?: any): AxiosPromise<WorkshopUserViewModel> {
            return localVarFp.apiWorkshopusersIdGet(id, workshopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWorkshopUserCommand} [updateWorkshopUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopusersPut(updateWorkshopUserCommand?: UpdateWorkshopUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkshopusersPut(updateWorkshopUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkshopUsersApi - object-oriented interface
 * @export
 * @class WorkshopUsersApi
 * @extends {BaseAPI}
 */
export class WorkshopUsersApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {Array<string>} [roleIds] 
     * @param {string} [workshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopUsersApi
     */
    public apiWorkshopusersGet(pageNumber?: number, pageSize?: number, searchText?: string, roleIds?: Array<string>, workshopId?: string, options?: AxiosRequestConfig) {
        return WorkshopUsersApiFp(this.configuration).apiWorkshopusersGet(pageNumber, pageSize, searchText, roleIds, workshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [workshopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopUsersApi
     */
    public apiWorkshopusersIdGet(id: string, workshopId?: string, options?: AxiosRequestConfig) {
        return WorkshopUsersApiFp(this.configuration).apiWorkshopusersIdGet(id, workshopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWorkshopUserCommand} [updateWorkshopUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopUsersApi
     */
    public apiWorkshopusersPut(updateWorkshopUserCommand?: UpdateWorkshopUserCommand, options?: AxiosRequestConfig) {
        return WorkshopUsersApiFp(this.configuration).apiWorkshopusersPut(updateWorkshopUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkshopsApi - axios parameter creator
 * @export
 */
export const WorkshopsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsForUserGet: async (pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshops/for-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {number} [statusId] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsGet: async (pageNumber?: number, pageSize?: number, searchText?: string, statusId?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (statusId !== undefined) {
                localVarQueryParameter['statusId'] = statusId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkshopsIdDelete', 'id', id)
            const localVarPath = `/api/workshops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkshopsIdGet', 'id', id)
            const localVarPath = `/api/workshops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWorkshopCommand} [updateWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdPut: async (id: string, updateWorkshopCommand?: UpdateWorkshopCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkshopsIdPut', 'id', id)
            const localVarPath = `/api/workshops/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkshopCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportWorkshopsCommand} [importWorkshopsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsImportPost: async (importWorkshopsCommand?: ImportWorkshopsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshops/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importWorkshopsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWorkshopCommand} [createWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsPost: async (createWorkshopCommand?: CreateWorkshopCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workshops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkshopCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkshopsApi - functional programming interface
 * @export
 */
export const WorkshopsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkshopsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsForUserGet(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfWorkshopViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsForUserGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {number} [statusId] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsGet(pageNumber?: number, pageSize?: number, searchText?: string, statusId?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedResultOfWorkshopViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsGet(pageNumber, pageSize, searchText, statusId, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWorkshopCommand} [updateWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsIdPut(id: string, updateWorkshopCommand?: UpdateWorkshopCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsIdPut(id, updateWorkshopCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportWorkshopsCommand} [importWorkshopsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsImportPost(importWorkshopsCommand?: ImportWorkshopsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfListOfString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsImportPost(importWorkshopsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateWorkshopCommand} [createWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkshopsPost(createWorkshopCommand?: CreateWorkshopCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JsonResponseOfGuid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkshopsPost(createWorkshopCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkshopsApi - factory interface
 * @export
 */
export const WorkshopsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkshopsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsForUserGet(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PagedResultOfWorkshopViewModel> {
            return localVarFp.apiWorkshopsForUserGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {number} [statusId] 
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsGet(pageNumber?: number, pageSize?: number, searchText?: string, statusId?: number, orderBy?: string, options?: any): AxiosPromise<PagedResultOfWorkshopViewModel> {
            return localVarFp.apiWorkshopsGet(pageNumber, pageSize, searchText, statusId, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkshopsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdGet(id: string, options?: any): AxiosPromise<WorkshopViewModel> {
            return localVarFp.apiWorkshopsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWorkshopCommand} [updateWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsIdPut(id: string, updateWorkshopCommand?: UpdateWorkshopCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkshopsIdPut(id, updateWorkshopCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportWorkshopsCommand} [importWorkshopsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsImportPost(importWorkshopsCommand?: ImportWorkshopsCommand, options?: any): AxiosPromise<JsonResponseOfListOfString> {
            return localVarFp.apiWorkshopsImportPost(importWorkshopsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWorkshopCommand} [createWorkshopCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkshopsPost(createWorkshopCommand?: CreateWorkshopCommand, options?: any): AxiosPromise<JsonResponseOfGuid> {
            return localVarFp.apiWorkshopsPost(createWorkshopCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkshopsApi - object-oriented interface
 * @export
 * @class WorkshopsApi
 * @extends {BaseAPI}
 */
export class WorkshopsApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsForUserGet(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsForUserGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {number} [statusId] 
     * @param {string} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsGet(pageNumber?: number, pageSize?: number, searchText?: string, statusId?: number, orderBy?: string, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsGet(pageNumber, pageSize, searchText, statusId, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsIdDelete(id: string, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsIdGet(id: string, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateWorkshopCommand} [updateWorkshopCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsIdPut(id: string, updateWorkshopCommand?: UpdateWorkshopCommand, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsIdPut(id, updateWorkshopCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportWorkshopsCommand} [importWorkshopsCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsImportPost(importWorkshopsCommand?: ImportWorkshopsCommand, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsImportPost(importWorkshopsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWorkshopCommand} [createWorkshopCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkshopsApi
     */
    public apiWorkshopsPost(createWorkshopCommand?: CreateWorkshopCommand, options?: AxiosRequestConfig) {
        return WorkshopsApiFp(this.configuration).apiWorkshopsPost(createWorkshopCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


